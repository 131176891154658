import React from "react";
import Logo from "../../assets/images/logo.svg";

if (typeof window !== `undefined`) {
  console.log(
    "%c Built & Managed     \n by GATSBOY          \n https://gatsboy.com ",
    "background: #062484; color: #fff; font-size: normal; padding: 10px;"
  );
}

const Footer = props => (
  <footer id="footer">
    <div className="container p-5">
      <div className="row">
        <div className="col-10">
          <h2>Get in touch</h2>
          <h3>
            To talk about your project contact our office on{" "}
            <a href="tel:07973817334">07973 817334</a> or <a href="mailto:interbuilding@icloud.com">interbuilding@icloud.com</a>
          </h3>
        </div>
        <div className="col-2">
          <img className="main-logo img-fluid" src={Logo} alt="main-logo" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 copyright text-right">
          <span className="Gatsboy">
            &copy; Interbuilding Projects {new Date().getFullYear()}. All Rights
            Reserved. Built and managed by{" "}
            <a
              href="https://gatsboy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              GATSBOY
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import Helmet from "react-helmet";
import Footer from "../layout/Footer";
import { StaticQuery, graphql } from "gatsby";
import { slide as Menu } from "react-burger-menu";
import Logo from "../../assets/images/logo.svg";

// code syntax-highlighting theme
// feel free to change it to another one
import "prismjs/themes/prism-twilight.css";

// main site style
import "./index.scss";

export function HamButton() {
  var button = document.getElementsByClassName("bm-burger-button");
  var bars = document.getElementsByClassName("bm-burger-bars");
  var i;
  for (i = 0; i < bars.length; i++) {
    //bars[i].removeAttribute("style");
  }

  button[0].onclick = function() {
    this.classList.toggle("open");
  };
}

class MobileMenu extends React.Component {
  componentDidMount() {
    HamButton();
  }

  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <Menu right className="mobile-menu">
        <Link to="/" className="nav-link">
          Home
        </Link>
        <Link to="/services" className="nav-link">
          Services
        </Link>
        <Link to="/gallery" className="nav-link">
          Gallery
        </Link>
        <Link to="/contact" className="nav-link">
          Contact
        </Link>
      </Menu>
    );
  }
}

const TemplateWrapper = ({ children, data }) => {
  return (
    <StaticQuery
      query={pageQuery}
      render={data => (
        <div className="App">
          <Helmet title={data.site.siteMetadata.title}  
          meta={[
            { name: 'description', content: 'We are an independent family run building and renovation company based in Fleet, and for over 30 years we have been providing a wide range of high-end services to transform properties.' },
            { name: 'google-site-verification', content: ''}
          ]}>

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.interbuildingprojects.co.uk/" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:description" content="We are an independent family run building and renovation company based in Fleet, and for over 30 years we have been providing a wide range of high-end services to transform properties." />
          <meta property="og:image" content={Logo}/>

          
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.interbuildingprojects.co.uk/" />
          <meta property="twitter:title" content={data.site.siteMetadata.title} />
          <meta property="twitter:description" content="We are an independent family run building and renovation company based in Fleet, and for over 30 years we have been providing a wide range of high-end services to transform properties." />
          <meta property="twitter:image" content={Logo} />

          </Helmet>
          <MobileMenu />
          <div className="navbar py-2">
            <Container>
              <Link to="/" className="navbar-brand col-4 mr-0 pl-0">
                <img
                  className="main-logo"
                  src={Logo}
                  alt={data.site.siteMetadata.title}
                />
              </Link>
              <ul className="nav navbar-nav main-menu col-sm-12 col-md-8 mr-0 pr-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link ml-4">
                    SERVICES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className="nav-link ml-4">
                    GALLERY
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link ml-4">
                    CONTACT
                  </Link>
                </li>
              </ul>
            </Container>
          </div>
          <div className="contentArea">
            <div className="row">
              <div className="pageContent col-md-12">{children}</div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.func
};

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default TemplateWrapper;
